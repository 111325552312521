import { useFormikContext } from "formik";
import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  CircularProgress,
  MenuItem,
  TextField,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  DialogActions
} from "@material-ui/core";
import { useDebounce } from "use-debounce";
import { Alert } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

import FinancingService from "../../../../../services/FinancingService";

function ArkeaInsuranceInput({ amount, deposit }) {
  const [open, setOpen] = useState(false);
  const { values, errors, touched, setFieldValue, handleBlur } = useFormikContext();
  const [debouncedNumberOfPayments] = useDebounce(values.numberOfPayments, 500);

  const { data: fetchedAssurances, error, isFetching, refetch } = useQuery({
    queryKey: [
      "arkea_assurances",
      {
        scaleId: values.scale && values.scale.id ? values.scale.id : null,
        numberOfPayments: debouncedNumberOfPayments,
        amount,
        deposit
      }
    ],
    queryFn: async () => {
      return FinancingService.getArkeaInsurances({
        scaleId: values.scale && values.scale.id ? values.scale.id : null,
        numberOfPayments: debouncedNumberOfPayments,
        purchaseAmount: amount,
        depositAmount: deposit
      })
        .then(res => {
          if (values.borrowerInsurance.id) {
            const insurance = res.find(_insurance => _insurance.id === values.borrowerInsurance.id);
            if (insurance) {
              setFieldValue("borrowerInsurance.insuranceWording", insurance.insuranceWording);
            }
          }
          return res;
        })
        .catch(err => {
          throw err;
        });
    },
    enabled:
      !!values.scale &&
      !!values.scale.id &&
      !!debouncedNumberOfPayments &&
      !errors.numberOfPayments &&
      debouncedNumberOfPayments > 1,
    retry: false
  });

  const handleChangeInsurance = event => {
    if (event.target.value === "NONE") {
      setFieldValue("borrowerInsurance.id", "NONE");
      setFieldValue("borrowerInsurance.insuranceWording", "Aucune assurance");
    } else {
      const insurance = fetchedAssurances.find(_insurance => _insurance.id === event.target.value);
      setFieldValue("borrowerInsurance.id", insurance.id);
      setFieldValue("borrowerInsurance.insuranceWording", insurance.insuranceWording);
    }
  };

  if (
    !!values.scale &&
    !!values.scale.id &&
    !!debouncedNumberOfPayments &&
    !errors.numberOfPayments &&
    fetchedAssurances
  ) {
    return (
      <Box display="flex" flexDirection="row" gap={1} alignItems="center">
        <TextField
          select
          label="Assurance"
          required
          value={values.borrowerInsurance.id}
          onChange={handleChangeInsurance}
          onBlur={handleBlur}
          helperText={
            touched.borrowerInsurance &&
            touched.borrowerInsurance.id &&
            errors.borrowerInsurance &&
            errors.borrowerInsurance.id
              ? errors.borrowerInsurance.id
              : ""
          }
          error={
            touched.borrowerInsurance &&
            touched.borrowerInsurance.id &&
            !!errors.borrowerInsurance &&
            !!errors.borrowerInsurance.id
          }
          name="borrowerInsurance.id"
          fullWidth
          variant="outlined"
        >
          {fetchedAssurances.map(borrowerInsurance => (
            <MenuItem key={borrowerInsurance.id} value={borrowerInsurance.id}>
              {borrowerInsurance.insuranceWording}
            </MenuItem>
          ))}
          <MenuItem value="NONE">Aucune assurance</MenuItem>
        </TextField>
        <IconButton variant="contained" color="primary" onClick={() => setOpen(true)}>
          <InfoIcon />
        </IconButton>

        <Dialog open={open} onClose={() => setOpen(false)}>
          <DialogTitle>Condition d&apos;adhésion aux formules de financement</DialogTitle>
          <DialogContent>
            <Box display="flex" flexDirection="column" style={{ gap: 16 }}>
              <Typography variant="body1">
                <strong>Premium</strong> : De 18 à - de 55 ans
              </Typography>
              <Typography variant="body1">
                <strong>Confort</strong> : De 18 à - de 65 ans
              </Typography>
              <Typography variant="body1">
                <strong>Sécurité</strong> : De 18 à - de 65 ans
              </Typography>
              <Typography variant="body1">
                <strong>Senior</strong> : De 65 à 79 ans
              </Typography>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Fermer</Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  if (isFetching && !errors.numberOfPayments) {
    return <CircularProgress />;
  }
  if (error && !errors.numberOfPayments) {
    return (
      <Alert
        severity="error"
        action={
          <Button color="inherit" size="small" onClick={() => refetch()}>
            Réessayer
          </Button>
        }
      >
        Une erreur est survenue lors de la récupération des assurances
      </Alert>
    );
  }
  return null;
}

export default ArkeaInsuranceInput;
