import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import { useFormik } from "formik";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Grid, TextField } from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import FinancingService from "../../services/FinancingService";

const ArkeaTransferContextSchema = Yup.object().shape({
  birthDate: Yup.date()
    .required("Ce champ est requis")
    .min(moment("1900-01-01"), "Ce champ est requis")
    .max(moment().subtract(18, "years"), "Le client ne peut pas être mineur")
    .nullable(),
  gender: Yup.string()
    .required("Ce champ est requis")
    .nullable(),
  birthName: Yup.string()
    .required("Ce champ est requis")
    .when("gender", {
      is: "F",
      then: Yup.string().required("Ce champ est requis"),
      otherwise: Yup.string().notRequired()
    })
});

export default function ArkeaTransferContextDialog({ doc, docType, onClose, open, onSuccess }) {
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = values => {
    FinancingService.sendArkeaContext(doc, docType, { ...values })
      .then(res => {
        onSuccess();
        enqueueSnackbar("La transmission des informations à Arkea a été effectuée avec succès", {
          variant: "success"
        });
        window.open(res.url);
      })
      .catch(err => {
        if (err && err.status === 401) {
          enqueueSnackbar("Vous n'avez pas configuré votre identifiant Arkea", {
            variant: "error"
          });
        } else if (err && (err.status === 400 || err.status === 412)) {
          enqueueSnackbar("Les données renseignées ne permettent pas de transmettre les informations à Arkea", {
            variant: "error"
          });
        } else {
          enqueueSnackbar("Une erreur est survenue lors de la transmission des informations à Arkea", {
            variant: "error"
          });
        }
      });
  };

  const { values, setFieldValue, handleSubmit: handleSubmitFormik, errors, touched, handleBlur } = useFormik({
    initialValues:
      doc && doc.contact && doc.contact.birthDate ? { birthDate: doc.contact.birthDate } : { birthDate: null },
    validationSchema: ArkeaTransferContextSchema,
    onSubmit: formData => {
      handleSubmit(formData);
    }
  });

  useEffect(() => {
    setFieldValue("birthDate", doc && doc.contact && doc.contact.birthDate ? doc.contact.birthDate : null);
    setFieldValue("gender", doc && doc.contact && doc.contact.gender ? doc.contact.gender : null);
    setFieldValue("birthName", doc && doc.contact && doc.contact.birthName ? doc.contact.birthName : null || "");
  }, [doc]);

  return (
    <Dialog aria-labelledby="simple-dialog-title" open={open} maxWidth={false} onClose={onClose}>
      <DialogTitle id="simple-dialog-title">Accès à Arkea</DialogTitle>
      <DialogContent style={{ width: "70vw", maxWidth: 900, display: "flex", flexDirection: "column", gap: 16 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body1">Veuillez vérifier les informations renseignées avant de continuer.</Typography>
          </Grid>
          {doc && (
            <Grid item xs={12}>
              <Typography variant="body1">
                Client :{" "}
                <strong>
                  {doc.contact.firstname} {doc.contact.lastname}
                </strong>
              </Typography>
            </Grid>
          )}

          <Grid item xs={12}>
            <ToggleButtonGroup
              size="small"
              style={{ width: "100%" }}
              value={values.gender}
              exclusive
              onChange={(e, newGender) => {
                setFieldValue("gender", newGender);
              }}
              aria-label="text alignment"
            >
              <ToggleButton value="M" aria-label="left aligned">
                Monsieur
              </ToggleButton>
              <ToggleButton value="F" aria-label="centered">
                Madame
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          {values.gender === "F" && (
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                size="small"
                name="birthName"
                label="Nom de naissance"
                fullWidth
                value={values.birthName}
                required={values.gender === "F"}
                onChange={e => {
                  setFieldValue("birthName", e.target.value);
                }}
                onBlur={handleBlur}
                error={!!errors.birthName && touched.birthName}
                helperText={touched.birthName && errors.birthName ? errors.birthName : ""}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <MuiPickersUtilsProvider utils={MomentUtils} locale="fr">
              <DatePicker
                size="small"
                name="effectiveDate"
                id="limitDate"
                cancelLabel="Annuler"
                format="D MMM YYYY"
                inputVariant="outlined"
                fullWidth
                label="Date de naissance"
                leftArrowIcon={<KeyboardArrowLeftIcon />}
                rightArrowIcon={<KeyboardArrowRightIcon />}
                minDate={moment("1900-01-01")}
                value={values.birthDate}
                onChange={birthDate => {
                  setFieldValue("birthDate", birthDate);
                }}
                helperText={touched.birthDate && errors.birthDate ? errors.birthDate : ""}
                error={!!errors.birthDate && touched.birthDate}
                required
                onBlur={handleBlur}
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="text" onClick={onClose}>
          Annuler
        </Button>
        <Button color="primary" variant="contained" onClick={handleSubmitFormik}>
          Continuer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
