import { inject, observer } from "mobx-react";
import React, { Component } from "react";
import { Box, Grid, Typography, withStyles } from "@material-ui/core";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Decimal from "decimal.js-light";
import moment from "moment";
import { withSnackbar } from "notistack";
import ArkeaScaleInput from "./Arkea/ScaleInput";
import ArkeaInsuranceInput from "./Arkea/InsuranceInput";
import ArkeaNumberOfPaymentsInput from "./Arkea/NumberOfPaymentsInput";
import ArkeaSubmitForSimulation from "./Arkea/SubmitForSimulation";
import FinancingService, { getTextFromInsuranceCount } from "../../../../services/FinancingService";
import FinancingItem from "./FinancingItem";
import { formatCurrency, formatNumber } from "../../../../utils/numberFormat";
import ArkeaDataDeltaAlert from "./Arkea/DataDeltaAlert";

const getInitialValues = financingForm => {
  if (financingForm._arkea) {
    return {
      scale: {
        id: financingForm._arkea.scaleId,
        scaleWording: financingForm._arkea.scaleWording,
        paymentPostponements: financingForm._arkea.paymentPostponements
      },
      borrowerInsurance: {
        id: financingForm._arkea.borrowerInsuranceId,
        insuranceWording: financingForm._arkea.insuranceWording
      },
      numberOfPayments: financingForm._arkea.numberOfPayments
    };
  }

  return {
    scale: { id: "", scaleWording: "", numberOfPaymentsMin: 0, numberOfPaymentsMax: 0, paymentPostponements: 0 },
    borrowerInsurance: {
      id: "",
      insuranceWording: "",
      insuranceType: "",
      insuranceRatio: 0,
      insuranceMonthlyAmount: 0
    },
    numberOfPayments: 1
  };
};

const ArkeaSchema = () =>
  Yup.lazy(values =>
    Yup.object().shape({
      scale: Yup.object().shape({
        id: Yup.string().required("Ce champ est requis"),
        scaleWording: Yup.string().required("Ce champ est requis"),
        creditAmountMin: Yup.number().required("Ce champ est requis"),
        creditAmountMax: Yup.number().required("Ce champ est requis"),
        creditDepositPercentMin: Yup.number().required("Ce champ est requis"),
        paymentPostponements: Yup.number().required("Ce champ est requis")
      }),
      borrowerInsurance: Yup.object().shape({
        id: Yup.string().required("Ce champ est requis"),
        insuranceWording: Yup.string().required("Ce champ est requis")
      }),
      numberOfPayments: Yup.number()
        .required("Ce champ est requis")
        .min(values.scale.numberOfPaymentsMin, `Doit être supérieur ou égal à ${values.scale.numberOfPaymentsMin}`)
        .max(values.scale.numberOfPaymentsMax, `Doit être inférieur ou égal à ${values.scale.numberOfPaymentsMax}`)
    })
  );

const styles = theme => ({
  totalCard: {
    backgroundColor: "#F7F7F7",
    borderRadius: 4,
    padding: `${theme.spacing(2)}px !important`
  }
});

@inject("newEstimateStore", "sessionStore")
@observer
class ArkeaForm extends Component {
  constructor(props) {
    super(props);
    const { newEstimateStore } = this.props;
    const simulationStatus =
      newEstimateStore.financingForm &&
      newEstimateStore.financingForm._arkea &&
      newEstimateStore.financingForm._arkea.simulationResult
        ? "DONE"
        : "DRAFT";
    this.state = { simulationStatus, legalNoticesText: null, isLoading: false };
  }

  render() {
    const { newEstimateStore, classes } = this.props;
    const { simulationStatus, legalNoticesText, isLoading } = this.state;
    const { loanAmount, financingForm, computedFinancingFormDeposit } = newEstimateStore;
    const amount = new Decimal(loanAmount).toNumber();
    const deposit = new Decimal(computedFinancingFormDeposit).plus(financingForm.endWorkPayment).toNumber();

    return (
      <Formik
        validationSchema={ArkeaSchema}
        initialValues={getInitialValues(financingForm)}
        onSubmit={values => {
          this.setState({ isLoading: true });
          FinancingService.getArkeaSimulation({
            purchaseAmount: amount,
            depositAmount: deposit,
            scaleId: values.scale.id,
            paymentPostponement: values.scale.paymentPostponements,
            numberOfPayments: values.numberOfPayments,
            borrowerInsuranceId: values.borrowerInsurance.id === "NONE" ? undefined : values.borrowerInsurance.id
          })
            .then(res => {
              const { loan, legalNotices } = res;
              this.setState({ legalNoticesText: legalNotices.html });
              newEstimateStore.patchFinancingForm({
                _arkea: {
                  simulationDate: new Date(),
                  simulationResult: { loan },
                  purchaseAmount: amount,
                  depositAmount: deposit,
                  scaleId: values.scale.id,
                  paymentPostponement: values.scale.paymentPostponements,
                  numberOfPayments: values.numberOfPayments,
                  borrowerInsuranceId: values.borrowerInsurance.id
                },
                financingApplicationFee: res.loan.fees,
                financingInstitution: "ARKEA",
                instalmentNumber: res.loan.numberOfPayments,
                loanDuration: res.loan.contractPeriod,
                instalmentAmount: res.loan.monthlyPaymentInsuranceNotIncluded,
                totalAmount: res.loan.totalAmountInsuranceNotIncluded,
                monthlyCostInsurance:
                  values.borrowerInsurance.id === "NONE" ? 0 : res.loan.borrowerInsurance.insuranceMonthlyAmount,
                insuranceCount: values.borrowerInsurance.id === "NONE" ? 0 : 1,
                instalmentAmountWithInsurance: res.loan.monthlyPaymentInsuranceIncluded,
                totalAmountWithInsurance: res.loan.totalAmountInsuranceIncluded,
                overdrawnRate: res.loan.fixedBorrowingRate,
                taeg: res.loan.annualPercentageRate,
                annualPercentageRateOfInsurance: res.loan.insuranceAnnualPercentageRate,
                annualMarkUprate: new Decimal(
                  (((res.loan.totalInterestAmount / res.loan.loanAmount) * 100) / res.loan.numberOfPayments) * 12
                )
                  .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
                  .toNumber()
                  .toFixed(2)
              });
              this.setState({ simulationStatus: "DONE", isLoading: false });
            })
            .catch(() => {
              const { enqueueSnackbar } = this.props;
              enqueueSnackbar("Une erreur est survenue lors de la simulation", {
                variant: "error"
              });
              this.setState({ isLoading: false });
            });
        }}
      >
        {({ values }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArkeaScaleInput toggleFormDisabled={this.setFormDisabled} amount={amount} deposit={deposit} />
                </Grid>
                <Grid item xs={12}>
                  <ArkeaNumberOfPaymentsInput toggleFormDisabled={this.setFormDisabled} />
                </Grid>
                <Grid item xs={12}>
                  <ArkeaInsuranceInput toggleFormDisabled={this.setFormDisabled} amount={amount} deposit={deposit} />
                </Grid>
                <Grid item xs={12}>
                  <ArkeaSubmitForSimulation disabled={isLoading} />
                </Grid>

                {simulationStatus === "DONE" && (
                  <Grid item xs={12}>
                    <ArkeaDataDeltaAlert
                      financingForm={financingForm}
                      amount={amount}
                      totalDeposit={deposit}
                      dueNumber={values.numberOfPayments}
                      insuranceId={values.borrowerInsurance.id}
                      scaleId={values.scale.id}
                    />
                  </Grid>
                )}

                {simulationStatus === "DONE" && (
                  <Box className={classes.totalCard}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem title="Durée du contrat" value={`${financingForm.loanDuration} mois`} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem title="Nombre de mensualités" value={financingForm.instalmentNumber} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Montant des mensualités hors assurance"
                          value={formatCurrency(financingForm.instalmentAmount)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Montant total dû hors assurance"
                          value={formatCurrency(financingForm.totalAmount)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Coût mensuel de l'assurance choisie"
                          value={formatCurrency(financingForm.monthlyCostInsurance)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Nombre d'assuré(s)"
                          value={getTextFromInsuranceCount(financingForm.insuranceCount)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Montant des mensualités avec assurance choisie"
                          value={formatCurrency(financingForm.instalmentAmountWithInsurance)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Montant total dû avec assurance choisie"
                          value={formatCurrency(financingForm.totalAmountWithInsurance)}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Taux Débiteur Fixe"
                          value={`${formatNumber(financingForm.overdrawnRate, 2)} %`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Taux Annuel Effectif Global (TAEG)"
                          value={`${formatNumber(financingForm.taeg, 2)} %`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Taux Annuel Effectif de l'Assurance (TAEA)"
                          value={`${formatNumber(financingForm.annualPercentageRateOfInsurance, 2)} %`}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FinancingItem
                          title="Taux de Majoration Annuel (TMA)"
                          value={`${formatNumber(financingForm.annualMarkUprate, 2)} %`}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FinancingItem
                          title="Frais de dossier"
                          value={formatCurrency(financingForm.financingApplicationFee)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* eslint-disable-next-line react/no-danger */}
                        <div dangerouslySetInnerHTML={{ __html: legalNoticesText }} />
                      </Grid>
                      <Grid item xs={12} style={{ textAlign: "center", marginTop: 20 }}>
                        <Typography variant="caption">
                          Simulation du {moment(financingForm._arkea.simulationDate).format("DD/MM/yyyy [à] HH:mm")}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    );
  }
}

export default withSnackbar(withStyles(styles)(ArkeaForm));
