import Decimal from "decimal.js-light";
import * as Yup from "yup";
import request, { METHOD } from "../utils/request";

export const INSURANCE_COUNT = [
  { value: 0, label: "Aucun" },
  { value: 1, label: "Un assuré" },
  { value: 2, label: "Deux assurés" }
];

export const INSURANCE_TYPE = [
  { value: "A", label: "Aucune assurance" },
  { value: "D", label: "Décès, invalidité, maladie (DIM)" },
  { value: "P", label: "DIM + CHOM" },
  { value: "S", label: "Sénior" }
];

export const getTextFromInsuranceCount = count => {
  const option = INSURANCE_COUNT.find(o => o.value === parseInt(count, 10));
  return `${count} - ${option.label || ""}`;
};

export const getTextFromInsuranceType = type => {
  const option = INSURANCE_TYPE.find(o => o.value === type);
  return option.label || "Type d'assurance inconnu";
};

export const SofincoSchema = () =>
  Yup.object().shape({
    dueNumbers: Yup.number()
      .min(3, "Saisie incorrecte, doit être supérieur à 3")
      .max(500, "Saisie incorrecte, doit être inférier à 500")
      .required("Ce champ est requis"),
    scaleCode: Yup.string().required("Ce champ est requis")
  });

class FinancingService {
  // montantAchat : amount total > numérique 2 décimales
  // montantApport : computedDeposit + endWorkPayment > numérique 2 décimales
  // [
  // dureeCredit : dueNumber > int
  //  OU
  // montantMensualiteCredit : monthlyAmount > numérique 2 décimales
  // ]
  // codeAssurance :

  static getFranfinanceScaleCodes({ amount, totalDeposit, dueNumber, monthlyAmount, insuranceType, equipmentCode }) {
    return request({
      url: "/franfinance/scaleCodes",
      method: METHOD.POST,
      data: {
        amount,
        totalDeposit,
        dueNumber,
        monthlyAmount,
        insuranceType,
        equipmentCode
      }
    });
  }

  static getFranfinanceSimulation({
    amount,
    totalDeposit,
    dueNumber,
    monthlyAmount,
    insuranceType,
    scaleCode,
    scaleCodeExpirationDate,
    equipmentCode
  }) {
    return request({
      url: "/franfinance/simulations",
      method: METHOD.POST,
      data: {
        amount,
        totalDeposit,
        dueNumber,
        monthlyAmount,
        insuranceType,
        scaleCode,
        scaleCodeExpirationDate,
        equipmentCode
      }
    });
  }

  static getSofincoSimulation({ brandStructure, scaleCode, dueNumbers, amount, equipmentCode, insurances = 0 }) {
    const _insurances = parseInt(insurances, 10);
    return request({
      url: "/sofinco/simulations",
      method: METHOD.POST,
      data: {
        brandStructure,
        scaleCode,
        dueNumbers: [dueNumbers],
        amount,
        equipmentCode,
        hasBorrowerInsurance: _insurances > 0,
        hasCoBorrowerInsurance: _insurances > 1
      }
    });
  }

  static sendFranfinanceContext(doc, type, transferType) {
    return request({
      url: `/franfinance/context?type=${type}&number=${doc.id}&transferType=${transferType}`,
      method: METHOD.POST
    });
  }

  static sendSofincoContext({ financingForm, externalId, contact, amount }) {
    const { loanAmount } = financingForm;
    const { simulationResult } = financingForm._sofinco;
    const purchaseAmount = new Decimal(amount).toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN).toNumber();
    const downPaymentAmount = new Decimal(financingForm.endWorkPayment)
      .add(financingForm.computedDeposit)
      .toDecimalPlaces(2, Decimal.ROUND_HALF_EVEN)
      .toNumber();
    return request({
      url: "/sofinco/context",
      method: METHOD.POST,
      data: {
        businessContext: JSON.stringify({
          uniqueExternalSaleId: externalId, // Identifiant unique du document de vente
          action: "SUBSCRIPTION",
          borrower: {
            name: contact.lastname,
            firstName: contact.firstname,
            addressStreet: contact.address,
            addressZipCode: contact.zipCode,
            addressCity: contact.city,
            phoneNumber: contact.phone,
            email: contact.email
          },
          simulation: {
            loanDuration: financingForm._sofinco.simulationResult.dueNumber, // WTF
            productNature: financingForm._sofinco.equipmentCode,
            purchaseOrder: externalId,
            purchaseAmount,
            loanAmount,
            downPaymentAmount,
            otherScale: financingForm._sofinco.scaleCode,
            priorSimulationResult: {
              monthlyAmountWithoutInsurance: simulationResult.monthlyAmountWithoutInsurance,
              monthlyAmountWithInsurance: simulationResult.monthlyAmountWithInsurance,
              totalAmountWithoutInsurance: simulationResult.totalAmountWithoutInsurance,
              totalAmountWithInsurance: simulationResult.totalAmountWithInsurance,
              borrowerMonthlyInsuranceAmount: simulationResult.borrowerMonthlyInsuranceAmount || 0,
              borrowerTotalInsuranceAmount: simulationResult.borrowerMonthlyInsuranceAmount || 0
            }
          },
          configContext: {
            configContextApplicationId: "ceasy",
            configContextPartnerId: "pdv_em",
            configContextSourceId: "vac_hab"
          }
        }),
        tokenFormat: "opaque"
      }
    });
  }

  static getArkeaScales() {
    return request({
      url: "/arkea/scales",
      method: METHOD.GET
    });
  }

  static getArkeaInsurances({ scaleId, purchaseAmount, depositAmount, numberOfPayments }) {
    return request({
      url: "/arkea/insurances",
      method: METHOD.POST,
      data: {
        scaleId,
        purchaseAmount,
        depositAmount,
        numberOfPayments
      }
    });
  }

  static getArkeaSimulation({
    purchaseAmount,
    depositAmount,
    scaleId,
    numberOfPayments,
    borrowerInsuranceId,
    paymentPostponement
  }) {
    return request({
      url: "/arkea/simulations",
      method: METHOD.POST,
      data: { purchaseAmount, depositAmount, scaleId, numberOfPayments, borrowerInsuranceId, paymentPostponement }
    });
  }

  static sendArkeaContext(doc, type, extraData) {
    return request({
      url: `/arkea/context?type=${type}&number=${doc.id}`,
      method: METHOD.POST,
      data: extraData
    });
  }
}

export default FinancingService;
